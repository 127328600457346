<template>
  <!-- 需求发布列表详情-需求应答列表 -->
  <div class="zt-page-content">
    <div
      v-if="item"
      class="zt-block"
      style="height: calc(100% - 107px); overflow: hidden"
    >
      <div class="item">
        <el-row type="flex" justify="space-between">
          <el-row>
            <h3 class="font14-grey ellipsis">
              <!-- <i class="el-icon-arrow-left"></i> -->
              需求名称:&nbsp;&nbsp;{{ item.title }}
            </h3>
            <p
              v-if="item[item.type] && item[item.type].cat"
              style="color: #b3b3b3; margin-bottom: 0"
            >
              {{ item[item.type].cat.name }}
            </p>
          </el-row>
          <el-button
            type="primary"
            @click="goto('/want/wantFollow/want/detail/' + $route.params.id)"
            >查看需求详情</el-button
          >
        </el-row>
      </div>
      <div
        v-if="list && list.length > 0"
        class="list"
        style="height: calc(100% - 100px); overflow: auto"
      >
        <div
          style="min-width: 1000px"
          v-for="(item1, idx) in list"
          :key="idx"
          class="list-item flex"
        >
          <div style="width: 300px; cursor: pointer">
            <div
              @click="
                goto(
                  '/want/wantFollow/pipe/detail/' +
                    $route.params.id +
                    '/' +
                    item1.id
                )
              "
              class="flex"
            >
              <div style="width: 106px; height: 106px; margin-right: 10px">
                <div
                  v-if="item1.comp && item1.comp.logo"
                  class="bg_img"
                  :style="{
                    backgroundImage:
                      'url(' + imgUrl + item1.comp.logo + '!width_250px' + ')',
                  }"
                ></div>
                <div
                  v-else
                  class="bg_img"
                  :style="{
                    backgroundImage:
                      'url(' +
                      staticUrl +
                      'design/placeholder/zhanweilogo%402x.jpg!width_250px' +
                      ')',
                  }"
                ></div>
              </div>
              <div
                v-if="item1.comp && item1.comp.name"
                style="text-align: left; position: relative"
                class="flex-1 flex-justify-between"
              >
                <h3 class="font14-grey ellipsis1">{{ item1.comp.name }}</h3>
                <el-button
                  @click.stop="viewIntro(item1.comp.id)"
                  style="color: #24B1EB; position: absolute; bottom: 0; left: 0"
                  type="text"
                  >查看公司详情资质 ></el-button
                >
              </div>
            </div>
          </div>
          <div class="list-item-tr" style="min-width: 300px; max-width: 350px">
            <div class="font14-grey1">出价</div>
            <div>￥{{ item1[item.type].price | priceFilt }}</div>
          </div>
          <div v-if="item1[item.type].giveDate" class="list-item-tr">
            <div class="font14-grey1">最早交货</div>
            <div>{{ item1[item.type].giveDate | moment("YYYY-MM-DD") }}</div>
          </div>
          <div style="padding: 10px 30px" class="list-item-tr flex-1">
            <el-button
              v-if="item.type != 'dayang' && item.type != 'gaikuang'"
              @click="viewProduct(item1)"
              size="mini"
              type="primary"
              round
              >查看商品</el-button
            >
            <!-- <el-button @click="reach(item)" v-if="item.state==3" size="mini" type="primary" plain round>达成需求</el-button> -->
            <el-button
              @click="
                goto(
                  '/want/wantFollow/pipe/detail/' +
                    $route.params.id +
                    '/' +
                    item1.id +
                    '?type=' +
                    [item.type]
                )
              "
              size="mini"
              type="text"
            >
              查看应答
              <!-- <span v-if="item.peitao && item.peitao.offers.length>0">({{item.peitao.offers.length}})</span>
              <span v-if="item.peitao && item.peitao.offers.length>0" class="alert-red"></span>-->
            </el-button>
            <el-button
              v-if="item1.orderId"
              type="text"
              @click="viewOrder"
              >查看需求订单</el-button
            >
            <el-button
              v-else
              @click="goOrder(item1)"
              size="mini"
              type="primary"
              round
              >生成订单并签约</el-button
            >
          </div>
        </div>
      </div>
      <div
        v-if="list && list.length == 0"
        style="text-align: center; padding-top: 80px"
      >
        <img
          style="width: 170px"
          :src="staticUrl + 'design/placeholder/zhanwuyingda.png'"
          alt
        />
        <p style="font-size: 18px; color: #cccccc; margin-top: 15px">
          暂无应答
        </p>
      </div>
    </div>
    <div style="padding: 7px">
      <el-pagination
        background
        layout="prev, pager, next,total"
        :current-page.sync="queryOpt.page.pageNumber"
        :page-size="queryOpt.page.pageCount"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { IMG_URL_PRE, STATIC_URL_PRE } from "@/config";
import { getRequireId, requireOfferList } from "@/service/want";
export default {
  components: {},
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      staticUrl: STATIC_URL_PRE,
      queryOpt: {
        where: {
          key: null,
          catIds: null,
        },
        page: {
          pageNumber: 1,
          pageCount: 10,
        },
      },
      total: 0,
      list: null,
      item: null,
    };
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true,
    },
  },
  created() {
    this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
    this.refreshItems();
  },
  methods: {
    viewOrder() {
      this.$router.push({
        path: "/factory/demandOrder",
        query: {
          type:
            this.item.type === "dayang" ? 0 : this.item.type === "gaikuang" ? 1 : null,
        },
      });
    },
    goOrder(item1) {
      this.goto(
        "/want/wantFollow/creatOrder?id=" +
          item1.id +
          "&wantId=" +
          this.$route.params.id
      );
    },
    goto(link) {
      this.$router.push(link);
    },
    back() {
      this.$router.go(-1);
    },
    refreshItems() {
      getRequireId(this.$route.params.id)
        .then((rst) => {
          this.item = rst;
        })
        .catch((err) => {
          console.error(err);
        });
      requireOfferList(this.$route.params.id)
        .then((rst) => {
          this.total = rst.count;
          this.list = rst.rows;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    viewIntro(id) {
      window.open("/zizhi/view/" + id);
    },
    viewProduct(item) {
      switch (this.item.type) {
        case "peitao":
          window.open(
            "/sale/mall/home/factory_detail/" + item[this.item.type].itemId
          );
          break;
        case "gaikuang":
          window.open(
            "/sale/mall/home/design_detail/" + item[this.item.type].itemId
          );
          break;
      }
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.item {
  padding: 20px;
  font-size: 14px;
  background: rgba(250, 250, 255, 1);
  border: 1px solid rgba(230, 230, 230, 1);
}
.item h3 {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
}
.list > .list-item {
  padding: 20px;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px 0px rgba(209, 217, 226, 1);
}
.list > .list-item:last-child {
  margin-bottom: 0;
}
.list > .list-item > div {
  min-width: 180px;
  min-height: 100px;
  padding: 0 30px;
  border-right: 1px solid #e6e6e6;
}
.list > .list-item > div:first-child {
  padding-left: 0;
  padding-right: 10px;
}
.list > .list-item > div:last-child {
  border-right: none;
}
.list > .list-item .list-item-tr > div:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
  margin-top: 10px;
  word-wrap: break-word;
}
.list-item-tr >>> button {
  margin: 5px;
}
.alert-red {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #f66f6a;
  border-radius: 50%;
  margin-left: 5px;
}
</style>